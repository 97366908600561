#callButton {
    background-color: #24117bff;
    border-radius:500px;
    color: #ffff13ff;
    padding: 6px 16px 7px 16px;
    font-size:xx-large;
    cursor:default;
    border: none;
    text-align: center;
    outline: none;
    font-weight: bold;
    text-decoration: none;
}

.profiles{
    z-index: 3;
}
.extension{
    text-decoration: none;
    color: black;
}
.links{
    line-height: 2.5;
}

#contactBlurb {
    font-size: xx-large;
    background-color: white;
    padding-top:15px;
    padding-bottom: 5vh;
    position: relative;
    z-index: 3;
}
#title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
}

#smallBlurb {
    font-size: large;
}

#DaveInfo, #AlixInfo, #LindsInfo, #JordynInfo, #SkyeInfo{
    visibility: hidden;
}

.emailIcon {
    width:3vw;
}
.callIcon{
    height:2vw;
}


@media screen and (min-width: 660px) and (orientation:landscape) { 

    .profiles{
        width:15%;
        height:35%;
        text-align:center;
        position:absolute;
    }

    #callNumber{
        position: absolute;
        z-index:3;
        bottom: -12%;
    }

    .infoBox {
        border: 2px solid black;
        border-style: dashed;
        border-radius: 15px;
        padding: 10px;
        background-color: white;
    }

    .contactContent{
        display:flex;
        justify-content:center;
        width: 100%;
        height: 100%;
        position: absolute;
        top:0px;
        left:0px;
    }
    
    #managersBox{
        position:absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    
    #managersImg {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
    }
    #managersHover {
        width: 100%;
        z-index: 2;
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .managerImages{
        width:100%;
        margin-bottom: 3%;
    }

    
    #LindseyImg {
        width:85%;
    }
    #Lindsey {
        z-index: 3;
    }
    #LindsInfo {
        position: absolute;
        top: 24vh;
        width:100%;
    }
    #JordynImg {
        width:90%;
    }
    #Jordyn {
        z-index: 2;
    }
    #Alix{
        z-index: 3;
    }
    #AlixInfo {
        position: absolute;
        bottom: 105%;
        left: 50%;
        width: 100%;
    }
    #SkyeImg {
        width:85%;
    }
    #Skye {
        z-index: 2;
    }
    #SkyeInfo{
        width: 75%;
        margin-left: 15%;
    }

    #title{
        margin-top:0px;
    }
    #contactBlurb {
        width:100%;
        text-align: center;
        padding-bottom: 7vh;
    }

    #callNumber {
        width:100%;
        text-align: center;
    }

    #callButton{
        font-size: xx-large;
    }

    #managerProfileBlocks{
        display: flex;
        justify-content: center;
    }

    #managerProfiles{
        width:100%;
        position: absolute;
        bottom:5%;
    }
}

@media screen and (max-height: 550px){
    #contactBlurb{
        font-size: medium;
    }

    .infoBox {
        border: 2px solid black;
        border-style: dashed;
        border-radius: 15px;
        padding: 10px;
        font-size: smaller;
    }

    #smallBlurb{
        font-size: small;
    }

    #callButton{
        font-size: large;
    }
    .profiles{
        font-size: small;
    }
    #managerProfiles{
        bottom:2%;
    }
}

@media screen and (min-width: 660px) and (orientation: portrait){ 

    .profiles{
        display:block;
        width:100%;
        height:20%;
        text-align:center;
        margin-top: 4%;
    }

    .infoBox {
        font-size: larger;
    }

    .emailIcon {
        width:6vw;
    }
    .callIcon{
        height:5vw;
    }

    #managersBox{
        position:absolute;
        top: 15%;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .managerImages{
        height:100%;
        margin-bottom: 3%;
        z-index: 3;
    }

    #contactBlurb {
        width:100%;
        height: 30%;
        text-align: center;
    }

    #callNumber {
        width:100%;
        text-align: center;
    }

    #managerProfileBlocks{
        width:100%;
        height:100%;
    }

    #managerProfiles{
        width:100%;
        height:90%;
        display: block;
        margin-top: 10%;
    }

    #smallBlurb {
        font-size: medium;
    }
}

@media screen and (max-width: 660px) { 

    .profiles{
        height:18%;
        width:30%;
        position:absolute;
    }

    .infoBox {
        width: 50vw;
        text-align:center;
        border: 2px solid black;
        border-style: dashed;
        border-radius: 15px;
        padding: 10px 10px 0px 10px;
        background-color: white;
        font-size: smaller;
    }
    .emailIcon {
        width:6vw;
    }
    .callIcon{
        height:5vw;
    }
    #contactBlurb {
        width:100%;
        padding-bottom: 25px;
        text-align: center;
        font-size: large;
        text-align: justify;
    }
    #managersBox{
        height: 80%;
        width: 100%;
        overflow: hidden;
    }

    #managersImg {
        width: 100%;
        object-fit: cover;
        object-position: 0 -80px;
        position: absolute;
        top:25vh;
        z-index: 1;
    }
    #managersHover {
        width: 100%;
        object-fit: cover;
        object-position: 0 -80px;
        z-index: 2;
        position: absolute;
        top:25vh;
    }

    .managerImages{
        height:26vw;
        margin-bottom: 3%;
        z-index: 3;
    }

    #JordynInfo{
        margin-left: -40%;
        width:40vw;
    }
    #Jordyn{
        z-index: 2;
    }
    #AlixInfo{
        width: 40vw;
        position: absolute;
        top: -13.5vh;
        left: -25vw;
    }
    #DaveInfo{
        width: 40vw;
        position: absolute;
        top: -5vh;
        left: -45vw;
    }
    #SkyeInfo{
        width: 30vw;
        margin-top: 5%;
        margin-left: -30%;
    }
    #LindsInfo{
        width: 40vw;
        position: absolute;
        top:22vh;
        left: -10vw;
    }

    #callNumber {
        width:100%;
        margin-top: 25px;
        text-align: center;
    }

    #managerProfileBlocks{
        display: flex;
        justify-content: center;
    }

    #managerProfiles{
        width:100%;
        position: absolute;
        bottom:5%;
    }

    #smallBlurb {
        font-size: medium;
    }
}



