#projectContent{
    width: 95%;
    height: 100%;
    background-color: #fff;
    position: relative;
    left:50%;
    transform: translateX(-50%);
}

#projectPage{
    width: 100%;
    height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
}