#fullCalendarButton {
    background-color: #24117bff;
    border-radius:500px;
    color: #fff;
    padding: 10px 20px 10px 20px;
    font-size:large;
    cursor: pointer;
    border: none;
    text-align: center;
    text-decoration: none;
    outline: none;
    font-weight: bold;
    font-family: Rubik;
}

#emailButton {
    background-color: #24117bff;
    border-radius:500px;
    color: #fff;
    padding: 10px 20px 10px 20px;
    font-size:large;
    cursor: pointer;
    border: none;
    text-align: center;
    text-decoration: none;
    outline: none;
    font-weight: bold;
    font-family: Rubik;
}

#searchCamps {
    font-family: Rubik;
    font-size: x-large;
    width:45%;
    display: table;
}

#searchResultBox{
    overflow-y: auto;
    overflow-x: hidden;
    width:55%;
    height:80%;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
#searchResultBox::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

#searchResults {
    padding: 10px;
    width:100%;
}

.filters {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    color: #24117bff;
    width: 100%;
    height: 30%;
}

.optionButtons{
    background-color: #fcfc7fff;
    border-radius:500px;
    border-width: 0;
    color: #24117bff;
    padding: 10px 15px 10px 15px;
    font-family: Rubik;
    font-size: large;
}

.moreBtnCont{
    text-align:center;
    display:none;
    width:95%;
    margin-left:2.5%;
    margin-bottom: 20px;
}
#moreBtn, #moreBtn2{
    background-color: #fcfc7fff;
    border-radius:500px;
    border-width: 0;
    color: #24117bff;
    padding: 10px 15px 10px 15px;
    font-family: Rubik;
    font-size: large;
    text-decoration: none;
}

.toggle{
    font-family: Rubik;
    font-size: large;
    text-align: center;
}
.toggle span {
    background-color: #24117bff;
    color: #ffffffff;
    padding: 7px;
}

.searchButton{
    background-color: #24117bff;
    border-radius:500px;
    color: #ffff13ff;
    padding: 10px;
    font-family: Rubik;
    font-size: x-large;
    cursor: pointer;
}

#fullCalendar {
    width:100%;
    text-align: center;
    position: absolute;
    bottom:10px;
}

#registerContent {
    width: 100%;
    height: 100%;
}

.foundCamp{
    width:85%;
    padding:20px;
    border-radius:500px;
    background-color: #fcfc7fff;
    position: relative;
}

.noCamps{
    margin-left: 2.5%;
    width:85%;
    padding:5%;
    border-radius:70px;
    font-size: x-large;
    text-align: center;
    background-color: #fcfc7fff;
    color: #24117bff;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.endFoundCamp{
    width:85%;
    padding:20px;
    border-radius:500px;
    left:50%;
    transform: translateX(-50%);
    position: relative;
    background-color: #fcfc7fff; 
    color: #24117bff;
    text-align:center;
    font-size:x-large;
}

.foundCampInfo{
    display: flex;
    justify-content: center;
    height: 100%;
}

.campIcon{
    width: 10%;
    display: inline-block; 
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.camp{
    width: 90%;
    position: relative;
    float: right;
    z-index: 2;
}

.campTitle {
    font-size: large;
    text-align: center;
}

.campInfo{
    text-align: center;
    font-size: medium;
}

.registerNow{
    text-align: center;
    margin-top: 10px;
}

.registerText{
    font-family: Rubik;
    font-size: large;
    padding: 10px 15px 10px 15px;
    background-color: #24117bff;
    border-radius:500px;
    color: #000;
    text-decoration: none;
}

@media screen and (min-width: 800px) and (orientation:landscape) { 
    #searchCamps {
        padding-bottom: 10px;
        font-family: Rubik;
        font-size: large;
        width:100%;
        position: relative;
        top:0px;
        display: table;
        z-index: 4;
    }
    #moreBtn{
        margin-left: 0px;
    }
    .noCamps{
        transform: translateY(0);
    }
    
    #fullCalendarButtonMobile {
        display:none;
    }
    #calendarBtnMobile{
        display: none;
    }

    #foundCampInfo{
        width: 80%;
    }
    .foundCamp{
        width:80%;
        margin-bottom: 10px;
        position: relative;
        left:50%;
        transform: translateX(-50%);
        z-index: 2;
    }
    .campIcon{
        width:12%;
    }

    #searchResultBox {
        position: absolute;
        width: 100%;
        z-index: 2;
    }
    
    
    #searchResults{
        padding:0px;
        position: relative;
        width: 95%;
        left: 50%;
        transform: translateX(-50%);
    }

    #mobileFilters{
        display: none;
    }

     /* Dropdown Button */
     .dropbtn {
        background-color: #24117bff;
        color: white;
        padding: 10px 20px 10px 20px;
        width: 100%;
        border-radius: 500px;
        margin-bottom: 5px;
        font-size: large;
        font-family: Rubik;
        border: none;
        cursor: pointer;
    }
    
    /* Dropdown button on hover & focus */
    .dropbtn:hover, .dropbtn:focus {
        background-color: #24117bff;
    }
    
    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
        display: inline-block;
        min-width: 230px;
        text-align: center;
        margin-left: 20px;
    }
    
    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #f1f1f1;
        font-family: Rubik;
        min-width: 230px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }
    
    /* Links inside the dropdown */
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-align: center;
        text-decoration: none;
        display: block;
    }
    
    /* Change color of dropdown links on hover */
    .dropdown-content a:hover {background-color: #ddd}
    
    /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
    .show {display:block;} 
}

@media screen and (min-width: 1000px){
    .foundCamp{
        width:65%;
    }
}

@media screen and (max-width: 800px){ 

    #fullCalendarButton {
        display: none;
    }
    #fullCalendarButtonMobile {
        background-color: #24117bff;
        border-radius:500px;
        color: #fff;
        padding: 10px 20px 10px 20px;
        font-size:large;
        cursor: pointer;
        border: none;
        text-align: center;
        text-decoration: none;
        outline: none;
        font-weight: bold;
        font-family: Rubik;
    }

    #emailButton {
        background-color: #24117bff;
        border-radius:500px;
        color: #fff;
        padding: 5px 15px 5px 15px;
        font-size:medium;
        cursor: pointer;
        border: none;
        text-align: center;
        text-decoration: none;
        outline: none;
        font-weight: bold;
        font-family: Rubik;
    }

    #calendarBtnMobile{
        display: block;
    }
    
    #searchCamps{
        display: none;
    }

    #moreBtn, #moreBtn2{
        font-size: large;
    }

    .noCamps{
        font-size: large;
        margin-left: 0px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    #searchResultBox{
        width:100%;
        height:100%;
    }
    #searchResults {
        width: 95%;
        padding-top: 0px;
    }
    #mobileFilters{
        margin-bottom: 10px;
    }

    .registerText{
        font-size: small;
    }
    .regOpen {
        font-size: small;
    }

    #foundCampInfo{
        width: 95%;
    }

    .foundCamp{
        z-index:1;
        border-radius: 90px;
        margin-bottom: 10px;
        position: relative;
        left:50%;
        transform: translateX(-50%);
    }
    .endFoundCamp{
        font-size:medium;
        margin-left: 0px;
        z-index:1;
        border-radius: 90px;
        position: relative;
        left:50%;
        transform: translateX(-50%);
    }

    .camp{
        width: 95%;
    }

    .campIcon{
        width: 12%;
    }
    
    .campTitle {
        font-size: medium;
    }
    
    .campInfo{
        font-size: small;
    }

     /* Dropdown Button */
    .dropbtn {
        background-color: #24117bff;
        color: white;
        padding: 7px 20px 7px 20px;
        border-radius: 500px;
        margin-bottom: 5px;
        font-size: 16px;
        font-family: Rubik;
        border: none;
        cursor: pointer;
    }
    
    /* Dropdown button on hover & focus */
    .dropbtn:hover, .dropbtn:focus {
        background-color: #24117bff;
    }
    
    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
        display: inline-block;
        width: 100%;
        text-align: center;
    }
    
    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #f1f1f1;
        font-family: Rubik;
        min-width: 100px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 3;
    }
    #intDropdown{
        width: 140px;
    }
    #regionDropdown {
        width:200px;
    }
    
    /* Links inside the dropdown */
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-align: center;
        text-decoration: none;
        display: block;
    }
    
    /* Change color of dropdown links on hover */
    .dropdown-content a:hover {background-color: #ddd}
    
    /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
    .show {display:block;} 
}

@media screen and (orientation: portrait){ 

    #fullCalendarButton {
        display: none;
    }
    #fullCalendarButtonMobile {
        background-color: #24117bff;
        border-radius:500px;
        color: #fff;
        padding: 10px 20px 10px 20px;
        font-size:large;
        cursor: pointer;
        border: none;
        text-align: center;
        text-decoration: none;
        outline: none;
        font-weight: bold;
        font-family: Rubik;
    }
    #calendarBtnMobile{
        display: block;
    }
    
    #searchCamps{
        display: none;
    }

    #moreBtn, #moreBtn2{
        font-size: large;
    }

    .noCamps{
        font-size: large;
        margin-left: 0px;
        position: relative;
        left: 50%;
        top: 0%;
        transform: translateX(-50%);
    }

    #searchResultBox{
        width:100%;
        height:100%;
    }
    #searchResults {
        width: 95%;
        padding-top: 0px;
    }
    #mobileFilters{
        margin-bottom: 10px;
    }

    .registerText{
        font-size: small;
    }
    .regOpen {
        font-size: small;
    }

    #foundCampInfo{
        width: 95%;
    }

    .foundCamp{
        z-index:1;
        position: relative;
        left:50%;
        transform: translateX(-50%);
        border-radius: 90px;
        margin-bottom: 10px;
    }
    .endFoundCamp{
        font-size:medium;
        margin-left: 0px;
        z-index:1;
        border-radius: 90px;
        position: relative;
        left:50%;
        transform: translateX(-50%);
    }

    .camp{
        width: 95%;
    }

    .campIcon{
        width: 12%;
    }
    
    .campTitle {
        font-size: medium;
    }
    
    .campInfo{
        font-size: small;
    }

     /* Dropdown Button */
    .dropbtn {
        background-color: #24117bff;
        color: white;
        padding: 7px 20px 7px 20px;
        border-radius: 500px;
        margin-bottom: 5px;
        font-size: 16px;
        font-family: Rubik;
        border: none;
        cursor: pointer;
    }
    
    /* Dropdown button on hover & focus */
    .dropbtn:hover, .dropbtn:focus {
        background-color: #24117bff;
    }
    
    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
        display: inline-block;
        width: 100%;
        text-align: center;
    }
    
    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #f1f1f1;
        font-family: Rubik;
        min-width: 100px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 3;
    }
    #intDropdown{
        width: 140px;
    }
    #regionDropdown {
        width:200px;
    }
    
    /* Links inside the dropdown */
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-align: center;
        text-decoration: none;
        display: block;
    }
    
    /* Change color of dropdown links on hover */
    .dropdown-content a:hover {background-color: #ddd}
    
    /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
    .show {display:block;} 
}

