/*.menu {
    position: absolute;
    top: 3em;
    left: 1em;
}*/

html {
    overflow: hidden;
    height: 100%;
}
body{
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    -webkit-overflow-scrolling: touch;
}

#websiteHack{
    pointer-events: none;
    position:absolute;
    bottom: 30px;
    left: 45px;
    font-size: 20px;
    z-index: 4;
}

#hacksTried{
    pointer-events: none;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

#hackText {
    border: none;
    background-color: transparent;
    font-size: 20px;
    pointer-events: all;
}

#hackText:focus {
    outline: none;
}


#menu {
    font-family:sans-serif;
    color:white;
    padding:20px;
    font-weight:bold;
    background:#543cbfff;
    left:0px;
    top:0px;
    padding:0px;
    margin:0px;
    vertical-align:top;
    position:fixed;
    z-index:3;
    transition: 0.7s;
}
    

      
.menu-content {
    margin-top:30px;
    display:none;
    margin:0px;
    z-index:4;
}

#menu-social-media {
    display:none;
    z-index:4;
}
#menu-social-media img{
    margin-right:5px;
}



.option {
    background-color: white;
    border-radius:500px;
    padding:10px;
    margin:2px;
    color: #543cbfff;
    cursor: pointer;
    padding-left: 14px;
    width: 100%;
    border: none;
    text-align: center;
    outline: none;
    font-weight: bold;
    transition: 1s;
}

#aboutus {
    text-align:center;
    display:flex;
}
#register {
    text-align:center;
    display:flex;
}
#learn {
    text-align:center;
    display:flex;
}
#main-buttons{
    color: #383b42ff;
    text-decoration: none;
    margin:auto;
}

a{
    cursor: pointer;
}

@media screen and (orientation: landscape){

    #menu {
        width:25px;
        height:100vh;
    }
    #menu:after {
        position:absolute;
        right:0px;
        top:45%;
        content: '\2807';
        font-size:30px;
        font-weight:300;
    } 

    #menu:hover {
        width:110px;
        height:100vh;
        padding-left:20px;
        padding-right:20px;
        display:inline-block;
        vertical-align:top;
        position:fixed;
        z-index:5;
        content:"";  
        font-size:12px;
        margin-bottom:10px; 
        text-align:center;
    }

    #menu:hover .menu-content {transition-delay:3s; display: block; z-index:4;}
    #menu:hover #menu-social-media {transition-delay:3s; display: block; position:absolute;bottom:10px;left:10px; z-index:4;}
     
    #menu:hover:after {
        content: '';
    }

    #menu-symbol {
        display: none;
    }
}


@media screen and (orientation: portrait){

    #menu {
        width:100vw;
        height:30px;
        text-align: center;
        font-size:30px;
        font-weight:bold;
        line-height: 0.5;
    }

    #menu.show{
        width:100vw;
        height:280px;
        padding-left:0px;
        padding-right:0px;
        display:inline-block;
        vertical-align:top;
        margin-bottom:10px; 
        text-align:center;
        position:fixed;
        z-index:3;
        content:"";  
    }
    .menu-content {
        display: block; 
        position: absolute;
        width: 100vw;
        bottom:60px;
        left:50%;
        transform: translateX(-50%);
        z-index:4;
    }
    #menu-social-media {
        display: block; 
        position:absolute;
        visibility: visible;
        bottom:10px;
        left:10px; 
        z-index:4;
        width: 100vw;
        text-align: center;
    }
    #menu-social-media.hide{
        visibility: hidden;
    }
    #menu-symbol.hide {
        display: none;
    }

    .option {
        padding-left: 0px;
        width: 80%;
        font-size: 14px;
    }
}

@media screen and (min-width: 700px) and (orientation: portrait){
    .option {
        width: 50%;
    }
}