
.series{
    width: 32.5%;
    height: 97%;
    border-radius: 100px;
    text-align: center;
}

.video{
    width:93%;
    overflow: hidden;
    margin: auto;
}

.videoDescription{
    width: 100%;
    text-align: center;
    display: table;
}
.description-content{
    display: table-cell;
    vertical-align: middle;
}
.seriesTitle{
    font-family: Rubik;
    font-size:x-large;
    padding-top: 40px;
    padding-bottom: 40px;
}
.info{
    font-size: medium;
    padding-left: 15%;
    padding-right: 15%;
}
.software{
    font-weight: bold;
    font-size: medium;
    margin-top: 20px;
}
.relCamps{
    font-weight: bold;
    font-size: medium;
    margin-top: 10px;
}
.seriesBtn{
    margin-top:25px;
    margin-bottom: 15%;
}
.swi{
    height:50px;
    width:auto;
    vertical-align: middle;
}
.moreBtn{
    background-color: #24117bff;
    border-radius:500px;
    color: #000;
    padding: 10px;
    font-size:large;
    font-family: Rubik;
    cursor: pointer;
    border: none;
    text-align: center;
    text-decoration: none;
    outline: none;
    font-weight: bold;
}

#tablet{
    float: left;
    background-color: #ffebddff;
}

#scratch{
    background-color: #bee4ffff;
}

#randomSeries{
    float: right;
    background-color: rgb(201, 252, 167);
}

@media (orientation:landscape){
    #scratch{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media screen and (max-height: 650px){
    .info{
        display:none;
    }
}

@media (orientation: portrait){
    .series{
        width:100%;
        height:46%;
        border-radius: 60px;
    }
    .seriesTitle{
        font-size: large;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .info, .software, .relCamps{
        display: none;
    }
    .videoDescription{
        bottom:0px;
    }
    .seriesBtn{
        margin-top:15px;
        margin-bottom: 25%;
    }
    .moreBtn{
        font-size: large;
    }
    #tablet{
        position:absolute;
        top:20px;
    }
    #scratch{
        position: absolute;
        bottom: 0px;
    }
    #randomSeries{
        display: none;
    }
}