#AboutUsContent{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x:hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
#AboutUsContent::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

#whoRweBox{
    background-color: #fff;
    position: relative;
    left:50%;
    transform: translateX(-50%);
    z-index: 0;
}

#whoRweText{
    text-align: center;
    width: 60%;
}
.title{
    font-family: Rubik;
    font-size: xx-large;
    color: #24117bff;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.aboutButtons{
    background-color: #24117bff;
    border-radius:500px;
    color: #ffffffff;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    border: none;
    text-decoration: none;
    outline: none;
    font-weight: bold;
    display: none;
}
#whoRweTitle, #missionTitle {
    font-size:large;
}
.text {
    text-align:justify;
    font-size: medium;
    margin-top: 0px;
    padding-top: 0px;
}
#whoRweBadges {
    width: 80%;
    padding-top: 15%;
    margin-bottom: 10px;
    position: relative;
    left:50%;
    transform: translateX(-50%);
}
#container {
    width:33%;
    display: inline-block;
    position:relative;
    text-align: center;
}
#youthCounter {
    font-family: Rubik;
    font-size: 200%;
    color: #ffad00ff;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#scholarshipCounter {
    font-family: Rubik;
    font-size: 170%;
    color: #ffad00ff;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#staff{
    width: 50%;
    text-align: center;
}
.sizzleReel{
    width:60%;
    margin-top: 30%;
    position: relative;
    left:50%;
    transform: translateX(-50%);
}
#mission{
    width: 50%;
    text-align: center;
}

#contactButton{
    background-color: #543cbfff;
    border-radius:500px;
    padding:10px;
    margin-top:10px;
    margin-bottom: 20%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    cursor: pointer;
    border: none;
    text-align: center;
    outline: none;
    font-family: 'Rubik';
    font-size: x-large;
    font-weight: bold;
}

#arrow{
    width:100%;
    position:absolute;
    bottom: 10px;
    z-index:2;
    -webkit-animation: fadeinout 6s infinite forwards;
    animation: fadeinout 6s infinite forwards;
}

@-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

#arrowImg{
    height: 50px;
    position:relative;
    left:50%;
    transform: translateX(-50%);
}

@media screen and (min-width: 660px) and (orientation:landscape) { 

    #whoRweBox{
        width: 85%;
        background-color: #fff;
        position: relative;
        left:50%;
        transform: translateX(-50%);
    }
    #whoRweText{
        position: relative;
        left:50%;
        transform: translateX(-50%);
        margin-bottom: 30px;
    }
    .title{
        margin-bottom: 15px;
    }
    .badges{
        width:100%;
    }
    #creativeTech{
        margin-top:20px;
        margin-bottom:0px;
    }
    #missionBox{
       width: 100%;
       position: relative;
    }
    #staff {
       width: 100%;
    }
    .sizzleReel{
        width: 70%;
        margin-top: 30%;
        position: relative;
        left:50%;
        transform: translateX(-50%);
    }
    #mission{
        width: 70%;
        margin-top: 30%;
        padding-bottom: 10%;
        position: relative;
        left:50%;
        transform: translateX(-50%);
    }

    .fade-in-section {
        opacity: 0;
    }
}


@media screen and (max-width: 660px) and (orientation: portrait){ 
    
    #whoRweText{
        width:90%;
        margin-left: 5%;
    }
    .title{
        font-size: x-large;
    }
    .text{
        font-size: 15px;
    }
    #whoRweBadges{
        width:95%;
        padding-top: 30px;
    }
    .badges{
        width:100%;
    }

    #staff{
        width: 100%;
    }
    .gif{
        width: 60%;
    }
    #mission{
        width: 90%;
    }
}

@media screen and (orientation: portrait){ 
    #AboutUsContent{
        overflow-y: auto;
    }
    #whoRweText{
        width:90%;
        margin-left: 5%;
        padding-top: 10px;
    }
    #whoRweBlurb{
        padding-top: 10px;
    }
    #creativeTech{
        margin-bottom:10px;
    }
    .aboutButtons{
        display: inline;
        margin-bottom: 15px;
        font-family: Rubik;
    }
    .text{
        font-size: 15px;
    }
    #whoRweBadges{
        width:95%;
        padding-top: 25%;
    }
    #youthCounter {
        font-size: 6vw;
    }
    #scholarshipCounter{
        font-size: 4.7vw;
    }
    .badges{
        width:100%;
    }

    #staff{
        width: 90%;
        margin-left: 5%;
    }
    .sizzleReel{
        width: 100%;
        margin-top: 40vh;
    }
    #mission{
        margin-top:40vh;
        padding-bottom:10%;
        width: 90%;
        position: relative;
        left:50%;
        transform: translateX(-50%);
        text-align: center;
    }
    #missionStatement{
        font-size: 15px;
    }
}